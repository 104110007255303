/* eslint-disable quotes */
/* eslint-disable react/prop-types */
import React from 'react';
import { Row, Col } from 'antd';

function Main({ children }) {
  return (
    <>
      <Row style={{ background: '100vh' }}>
        <Col sm={24} style={{ overflowX: 'hidden' }}>
          {children}
        </Col>
      </Row>
    </>
  );
}

export default Main;
