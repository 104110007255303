const PORT = process.env.REACT_APP_PORT || ''
const TOKEN_URL = process.env.REACT_APP_TOKEN_URL || ''
const CLIENT_ID = process.env.REACT_APP_CLIENT_ID || ''
const CLIENT_SECRET = process.env.REACT_APP_CLIENT_SECRET || ''

const dotEnv = {
  PORT,
  TOKEN_URL,
  CLIENT_ID,
  CLIENT_SECRET
};

export default dotEnv;

