/* eslint-disable quotes */
/* eslint-disable comma-dangle */
/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './custom.scss';

ReactDOM.render(
  <React.StrictMode>
    <App style={{ fontFamily: "'Inter', sans-serif" }} />
  </React.StrictMode>,
  document.getElementById('root'),
);
