/* eslint-disable quotes */
import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Card } from 'antd';
import styled from 'styled-components';
import { ReactComponent as Data } from '../images/data.svg';
import { ReactComponent as Fragment } from '../images/fragment.svg';
import { ReactComponent as Gear } from '../images/gear.svg';
import { ReactComponent as Scalability } from '../images/scalability.svg';
import { ReactComponent as Target } from '../images/target.svg';
import { ReactComponent as Workflow } from '../images/workflow.svg';

const { Meta } = Card;

function Features() {
  return (
    <>
      <ContainerStyled id="features" fluid>
        <Container style={{ marginTop: '50px' }}>
          <Row>
            <Col sm>
              <CardStyled hoverable cover={<Gear />}>
                <MetaStyled
                  title="Manage Everything"
                  description="Tracking Devices and People for better management, all in a single event & action. Example of onboarding or terminating an employee."
                />
              </CardStyled>
            </Col>
            <Col sm>
              <CardStyled hoverable cover={<Workflow />}>
                <MetaStyled
                  title="Friendly Workflows"
                  description="Rundis allows you to set up you automation easily within 60 seconds, even without any coding experience. User friendly front-end Interface for anyone."
                />
              </CardStyled>
            </Col>
            <Col sm>
              <CardStyled hoverable cover={<Scalability />}>
                <MetaStyled
                  title="Easy Scalability"
                  description="Rundis allows you to set up you automation easily within 60 seconds, even without any coding experience. User friendly front-end Interface for anyone."
                />
              </CardStyled>
            </Col>
          </Row>
          <Row>
            <Col sm>
              <CardStyled hoverable cover={<Data />}>
                <MetaStyled
                  title="Data Intelligence"
                  description="Data providers to ingest the data, Collections to categorize and group data sets."
                />
              </CardStyled>
            </Col>
            <Col sm>
              <CardStyled hoverable cover={<Fragment />}>
                <MetaStyled
                  title="Language Agnostic"
                  description="The product is language agnostic allowing for custom actions. Tool integrates the change does that for you. It works similar to what AWS lambda does that for you."
                />
              </CardStyled>
            </Col>
            <Col sm>
              <CardStyled hoverable cover={<Target />}>
                <MetaStyled
                  title="Custom Actions"
                  description="This comes with custom actions, for instance they may have azure functions, that are setup in separate modules and the gateway will trigger that action."
                />
              </CardStyled>
            </Col>
          </Row>
        </Container>
      </ContainerStyled>
    </>
  );
}

const ContainerStyled = styled(Container)`
  background: linear-gradient(
    359.68deg,
    rgb(255, 140, 46, 0.1),
    rgb(112, 0, 156, 0.1)
  );
  /* opacity: 0.1; */
  padding: 140px;
  border-radius: 4px;

  @media only screen and (max-width: 681px) {
    padding: 2rem;
  }
`;

const CardStyled = styled(Card)`
  min-width: 240px;
  max-width: 400px;
  margin-bottom: 50px;
  background: inherit;

  .ant-card-cover, .ant-card-body {
     padding: 2rem;
  }

  .ant-card-cover {
    padding-bottom: 0px;
  }

  @media only screen and (max-width: 681px) {
    .ant-card-cover {
      display: flex;
      justify-content: center;
    }
  }
`;
const MetaStyled = styled(Meta)`
  margin: 1rem 0;
  .ant-card-meta-detail .ant-card-meta-title {
    margin: 1rem 0;
    font-weight: 600;
    color: rgb(112, 0, 156);
  }
  .ant-card-meta-detail .ant-card-meta-description {
    margin: 1rem 0 1rem 0;
  }
  @media only screen and (max-width: 681px) {
    .ant-card-meta-detail .ant-card-meta-title {
      text-align: center;
    }
    .ant-card-meta-detail .ant-card-meta-description {
      text-align: center;
    }
  }
`;

export default Features;
