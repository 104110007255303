/* eslint-disable quotes */
/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';

function SvgIcon(props) {
  return (
    <svg
      width="108"
      height="32"
      viewBox="0 0 108 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M15.295 31.36L8.93 19.396H5.17v11.962H0V1.07h11.62c2.85 0 5.142.882 6.88 2.648 1.737 1.737 2.605 3.916 2.605 6.537 0 2.164-.612 4.016-1.837 5.554-1.224 1.538-2.89 2.563-4.998 3.076l6.835 12.475h-5.81zM5.17 14.867h5.51c1.567 0 2.82-.413 3.76-1.24.969-.854 1.453-1.979 1.453-3.374 0-1.424-.484-2.55-1.452-3.375-.94-.855-2.194-1.282-3.76-1.282H5.17v9.27z"
        fill="url(#icon_svg__paint0_angular)"
      />
      <path
        d="M35.222 29.052c-.484.912-1.281 1.624-2.392 2.136a8.08 8.08 0 01-3.504.77c-2.42 0-4.343-.784-5.767-2.35-1.396-1.567-2.094-3.547-2.094-5.94V10.554h4.956v12.219c0 1.395.342 2.535 1.026 3.418.683.883 1.737 1.324 3.161 1.324 1.396 0 2.464-.427 3.204-1.282.77-.854 1.154-1.98 1.154-3.375V10.553h4.956v17.004c0 1.253.071 2.52.214 3.802h-4.743c-.114-.627-.17-1.396-.17-2.307z"
        fill="url(#icon_svg__paint1_angular)"
      />
      <path
        d="M47.448 19.354v12.005h-4.956V10.553h4.827v2.777c.655-1.14 1.524-1.98 2.607-2.52a7.662 7.662 0 013.546-.855c2.45 0 4.315.769 5.596 2.307 1.282 1.538 1.923 3.517 1.923 5.938v13.16h-4.956V19.054c0-1.396-.342-2.507-1.025-3.333-.684-.854-1.766-1.281-3.247-1.281-1.367 0-2.436.47-3.205 1.41-.74.91-1.11 2.079-1.11 3.503z"
        fill="url(#icon_svg__paint2_angular)"
      />
      <path
        d="M66.512 20.892c0 1.994.512 3.603 1.538 4.828 1.025 1.196 2.378 1.794 4.059 1.794 1.623 0 2.947-.612 3.973-1.837 1.025-1.225 1.538-2.834 1.538-4.828 0-1.965-.499-3.532-1.496-4.7-.996-1.167-2.32-1.751-3.973-1.751-1.652 0-3.005.598-4.058 1.794-1.054 1.168-1.581 2.735-1.581 4.7zM82.405.427v27.13c0 1.395.071 2.663.213 3.802h-4.742a15.395 15.395 0 01-.213-2.691c-.513.94-1.31 1.709-2.393 2.307-1.082.598-2.321.897-3.717.897-2.962 0-5.369-1.04-7.22-3.119-1.852-2.08-2.777-4.7-2.777-7.861 0-3.048.925-5.625 2.777-7.733 1.88-2.108 4.258-3.162 7.135-3.162 1.652 0 2.976.3 3.973.898 1.025.598 1.737 1.296 2.136 2.093V.428h4.828z"
        fill="url(#icon_svg__paint3_angular)"
      />
      <path
        d="M89.948 31.36h-4.913V10.552h4.913v20.806zM85.206 5.51a3.086 3.086 0 01-.94-2.264c0-.883.313-1.638.94-2.264C85.832.328 86.587 0 87.47 0c.912 0 1.68.313 2.307.94s.94 1.396.94 2.307c0 .883-.313 1.638-.94 2.264-.626.627-1.395.94-2.307.94a3.086 3.086 0 01-2.264-.94z"
        fill="url(#icon_svg__paint4_angular)"
      />
      <path
        d="M90.66 25.72l4.315-1.197a3.758 3.758 0 001.239 2.521c.74.684 1.737 1.025 2.99 1.025.969 0 1.723-.227 2.265-.683.569-.456.854-1.026.854-1.71 0-1.195-.812-1.964-2.435-2.306l-3.076-.684c-1.795-.398-3.176-1.139-4.144-2.221-.969-1.083-1.453-2.364-1.453-3.846 0-1.822.74-3.389 2.222-4.7 1.48-1.338 3.29-2.007 5.425-2.007 1.368 0 2.578.2 3.632.598 1.054.399 1.866.926 2.435 1.58.598.627 1.054 1.24 1.367 1.838.314.598.513 1.196.599 1.794l-4.187 1.197c-.114-.798-.485-1.51-1.111-2.137-.598-.655-1.51-.982-2.734-.982-.855 0-1.567.242-2.137.726-.57.456-.854 1.011-.854 1.666 0 1.168.698 1.894 2.093 2.18l3.162.64c1.937.427 3.418 1.196 4.443 2.307 1.025 1.11 1.538 2.45 1.538 4.016 0 1.738-.698 3.29-2.093 4.657-1.367 1.339-3.29 2.008-5.768 2.008-1.424 0-2.706-.214-3.845-.64-1.11-.428-1.994-.97-2.649-1.624-.627-.684-1.125-1.353-1.495-2.008-.342-.684-.541-1.353-.598-2.008z"
        fill="url(#icon_svg__paint5_angular)"
      />
      <defs>
        <radialGradient
          id="icon_svg__paint0_angular"
          cx={0}
          cy={0}
          r={1}
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(.79159 -29.80209 99.71413 2.64855 63.66 16)"
        >
          <stop offset={0.382} stopColor="#FF8C2E" />
          <stop offset={0.714} stopColor="#70009C" />
        </radialGradient>
        <radialGradient
          id="icon_svg__paint1_angular"
          cx={0}
          cy={0}
          r={1}
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(.79159 -29.80209 99.71413 2.64855 63.66 16)"
        >
          <stop offset={0.382} stopColor="#FF8C2E" />
          <stop offset={0.714} stopColor="#70009C" />
        </radialGradient>
        <radialGradient
          id="icon_svg__paint2_angular"
          cx={0}
          cy={0}
          r={1}
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(.79159 -29.80209 99.71413 2.64855 63.66 16)"
        >
          <stop offset={0.382} stopColor="#FF8C2E" />
          <stop offset={0.714} stopColor="#70009C" />
        </radialGradient>
        <radialGradient
          id="icon_svg__paint3_angular"
          cx={0}
          cy={0}
          r={1}
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(.79159 -29.80209 99.71413 2.64855 63.66 16)"
        >
          <stop offset={0.382} stopColor="#FF8C2E" />
          <stop offset={0.714} stopColor="#70009C" />
        </radialGradient>
        <radialGradient
          id="icon_svg__paint4_angular"
          cx={0}
          cy={0}
          r={1}
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(.79159 -29.80209 99.71413 2.64855 63.66 16)"
        >
          <stop offset={0.382} stopColor="#FF8C2E" />
          <stop offset={0.714} stopColor="#70009C" />
        </radialGradient>
        <radialGradient
          id="icon_svg__paint5_angular"
          cx={0}
          cy={0}
          r={1}
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(.79159 -29.80209 99.71413 2.64855 63.66 16)"
        >
          <stop offset={0.382} stopColor="#FF8C2E" />
          <stop offset={0.714} stopColor="#70009C" />
        </radialGradient>
      </defs>
    </svg>
  );
}

export default SvgIcon;
