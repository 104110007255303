/* eslint-disable comma-dangle */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable object-curly-newline */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable quotes */
import React from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import { Container, Row, Col } from 'react-bootstrap';
// eslint-disable-next-line object-curly-newline
import { Form, Input, Button, Typography, Select } from 'antd';
// import { UserOutlined } from "@ant-design/icons";
// import createFormPost from "../server/createFormPost";

const { Title, Text } = Typography;
const { TextArea } = Input;
const { Option } = Select;
const { Item, useForm } = Form;

function ContactForm() {
  // eslint-disable-next-line object-curly-newline
  const [contactForm] = useForm();
  const companySizes = [
    '< 1000',
    '> 1000 & & < 5000',
    '> 5000 & < 10000',
    '> 10000 & < 20000',
    '> 20000',
  ];

  const onFinish = inputs => {
    // const onFinish = (e, inputs) => {
    // e.preventDefault();
    // eslint-disable-next-line
    console.log('hello eliot! ', inputs);
    // console.log(inputs);
    // createFormPost(inputs, url);
  };

  const onReset = e => {
    e.preventDefault();
    contactForm.resetFields();
  };

  return (
    <>
      <ContactFormGlobalStyle />
      <FormContainer fluid>
        <Container id="#contact-form" className="text-light">
          <TopRow>
            <Col className="text-center">
              <TopText>Get Started</TopText>
              <FormTitle className="text-light">Contact Us</FormTitle>
              <BottomText>
                We’d love to talk how we can work together with you?
              </BottomText>
            </Col>
          </TopRow>
          <Row className="mx-auto">
            <FormCol className="text-center mx-auto">
              <RundisForm
                layout="horizontal"
                form={contactForm}
                size="large"
                initialValues={{
                  remember: true,
                }}
                onFinish={() => onFinish()}
              >
                <div className="form-row">
                  <Item
                    className="name-group col-md-6 form-group text-left"
                    label="Your Name"
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Input
                      className="form-control"
                      placeholder="First & Last Name"
                      name="fullName"
                      rules={{ required: true }}
                    />
                  </Item>
                  <Item
                    className="email-group col-md-6 form-group text-left"
                    label="Your Email"
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Input
                      className="form-control"
                      placeholder="Enter your email"
                      name="email"
                      rules={{
                        pattern: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                        required: true,
                      }}
                    />
                  </Item>
                </div>
                <div className="form-row">
                  <Item
                    className="size-group col-md-6 form-group text-left"
                    label="Company Size"
                    htmlFor="size"
                  >
                    <Select
                      className="form-control text-left"
                      name="size"
                      id="size"
                      placeholder="Company Size"
                      allowClear
                    >
                      {companySizes.map(size => (
                        <Option
                          key={size}
                          className="bg-white text-left"
                          value={size}
                        >
                          {size}
                        </Option>
                      ))}
                    </Select>
                  </Item>
                  <Item
                    className="website-group col-md-6 form-group text-left"
                    label="Website (URL)"
                  >
                    <Input
                      className="form-control"
                      placeholder="Company Website"
                      name="website"
                      rules={{
                        pattern: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9-]+)*$/,
                      }}
                    />
                  </Item>
                </div>
                <div className="form-row">
                  <Item
                    className="note-group col-md-12 form-group text-left"
                    label="Tell us about it in detail?"
                  >
                    <TextArea
                      className="form-control "
                      rows="5"
                      placeholder="Your Message"
                      name="note"
                    />
                  </Item>
                </div>
                <div className="form-row">
                  <Item className="button-group">
                    <Button
                      className="text-light"
                      type="primary"
                      htmlType="submit"
                    >
                      Contact Us
                    </Button>
                    <Button id="reset-btn" htmlType="button" onClick={onReset}>
                      Reset
                    </Button>
                  </Item>
                </div>
              </RundisForm>
            </FormCol>
          </Row>
        </Container>
      </FormContainer>
    </>
  );
}

const ContactFormGlobalStyle = createGlobalStyle`

.ant-form-item {
  display: flex;
  flex-direction: column;
}
.ant-form-item-label label {
  color: #fff;
}
`;

const FormContainer = styled(Container)`
  background: linear-gradient(
    327.15deg,
    #ff8c2e -1.6%,
    #70009c 59.76%,
    #70009c 74.57%
  );
  border-radius: 4px;
  min-height: 860px;
  color: #fff;
`;

const TopRow = styled(Row)`
  padding-top: 60px;
  padding-bottom: 50px;
  color: #fff;
`;
const TopText = styled(Text)`
  font-family: Inter;
  font-weight: 600;
  font-size: 12px;
  line-height: 120%;
  /* or 14px */
  letter-spacing: 0.105em;
  text-transform: uppercase;
  color: #fff;
`;
const FormTitle = styled(Title)`
  /* H3 */
  font-family: Inter;
  font-weight: 600;
  font-size: 48px;
  line-height: 120%;
  /* identical to box height, or 58px */
  letter-spacing: -0.05em;
  color: #fff;
`;
const BottomText = styled(Text)`
  /* Paragraph */
  font-family: Inter;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  letter-spacing: -0.02em;
  color: #fff;
`;

const FormCol = styled(Col)`
  max-width: 750px;

  @media only screen and (max-width: 769px) {
    max-width: auto;
  }
`;

const RundisForm = styled(Form)`
  color: #fff;

  .ant-form-item-label {
    text-align: left;
    color: #fff;
  }

  .ant-form-item-control-input-content {
    background: #ffffff;
    /* Mono/Gray 2 */

    border: 1px solid #dde2e5;
    box-sizing: border-box;
    border-radius: 8px;
  }

  }
  .ant-form-item-control-input-content .ant-input::placeholder, .ant-select-selection-placeholder, .ant-select {
    padding-top: 5px;
    padding-left: 5px;
    font-family: Inter;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    /* identical to box height, or 125% */
    /* Gray 4 */
    color: #bdbdbd;
    border-top-style: hidden;
    border-right-style: hidden;
    border-left-style: hidden;
    border-bottom-style: hidden;
  }

  .ant-input:focus, .ant-selector:focus, .ant-select {
    outline: none;
  }

  .ant-form-item-control-input-content .ant-input::placeholder, .ant-select-selection-placeholder { /* Firefox 18- */
    color: grey;
  }

  #size {
    padding: 6px;
    width: 100%;
  }

  .button-group .ant-form-item-control-input-content, .ant-select .ant-select-selector, .ant-select-selection-search-input, .ant-select {
    background: inherit;
    border: none;
    display: flex;
    justify-content: flex-start;
  }

  .ant-select {
    padding: 6px;
  }

  .button-group button {
    border-radius: 4px;
    border: #3f3f3f 1px solid;
    height: 40px;
    margin-left: 1rem;

    font-family: Inter;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    /* identical to box height *
      /* Black */
    color: #3f3f3f;
  }

  .button-group button:hover {
    background-color: #550076;
    border-color: #4b0069;
  }

  .button-group button:active, .button-group button:focus {
    color: #fff;
    background: #FF8C2E;
    border-color: #FF8C2E;

  }
  .button-group #reset-btn::hover {
    background-color: lightgrey;
    border-color: #4b0069;
  }

  .ant-select .ant-select-selector::focus, .ant-select, .ant-selct::focus {
    border: none;
  }

  .ant-select-dropdown {
    background: #ffffff;
    /* Mono/Gray 2 */

    border: 1px solid #dde2e5;
    box-sizing: border-box;
    border-radius: 8px;
  }
  .rc-virtual-holder{
    width: auto;
    max-width: 85%;
  }

  #reset-btn:hover {
    color: #fff;
  }

  @media only screen and (max-width: 769px) {
    .button-group {
      margin: 50px auto 100px auto;
    }
  }
`;

export default ContactForm;
