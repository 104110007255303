/* eslint-disable object-curly-newline */
/* eslint-disable import/no-unresolved */
/* eslint-disable quotes */
import React from 'react';
import { Button, Row, Col, Image } from 'react-bootstrap';
import styled, { createGlobalStyle } from 'styled-components';
import dashboard from '../images/dashboard-view.svg';
import provider1 from '../images/provider1.png';
import provider2 from '../images/provider2.png';

// eslint-disable-next-line react/prop-types
function Banner({ setModalVisible }) {
  return (
    <>
      <BannerGlobalStyle />
      <Row
        id="home"
        style={{
          width: '100vw',
          margin: '0',
          background:
            'linear-gradient(71.81deg, #FF8C2E -21.73%, #CB5956 24.45%, #70009C 75.03%)',
        }}
        className="mb-6 d-flex flex-column justify-content-center align-items-center"
      >
        <Col xs={10} md={5}>
          <header
            className="d-flex flex-column align-items-center justify-content-center text-white"
            style={{ height: '85vh' }}
          >
            <BannerTitle className="text-center text-light">
              Track & Automate
              <br />
              for Better Workflow
            </BannerTitle>
            <p className="text-center">
              Easy-to-Use Automation & Intelligence tool for Effective Team &
              Enterprise-Level Organization. Consumes Company Data and Automates
              Workflows.
            </p>
            <Button
              className="bg-white text-dark"
              style={{ width: '211px', height: '54px', borderRadius: '8px', zIndex: '999' }}
              onClick={() => setModalVisible(true)}
            >
              Sign Up for Free
            </Button>
          </header>
        </Col>
      </Row>
      <DashboardRow className="d-flex justify-content-center">
        <DashboardCol xs={11} className="d-flex flex-column align-items-center">
          <DashboardImage
            src={dashboard}
            alt="rundis dashboard view"
            className="mt-5"
            fluid
          />

          <ProviderContainer className="d-flex flex-column align-items-center justify-content-center">
            <Col xs={9}>
              <ContainerTitle className="mb-5 text-center">
                Made for your Business & Agile Team
              </ContainerTitle>
            </Col>
            <Row
              className="mb-2 d-lg-flex d-xl-flex d-xs-none d-sm-none d-md-flex justify-content-center"
              style={{ width: '100vw' }}
            >
              <Col xs={9} sm={10} md={4}>
                <ProviderImage
                  src={provider1}
                  alt="Code editor with new hire information"
                  style={{ width: '360px' }}
                  className="mb-5 border-0"
                  fluid
                />
              </Col>
              <Col xs={9} sm={10} md={4}>
                <ProviderImage
                  src={provider2}
                  alt="User interface with new hire data providers listed"
                  style={{ width: '360px' }}
                  className="mb-5 border-0"
                  fluid
                />
              </Col>
            </Row>
            <Row
              style={{ marginBottom: '120px' }}
              className="d-flex align-items-center justify-content-center"
            >
              <Col
                xs={9}
                sm={10}
                md={4}
                className="d-flex flex-column align-sm-items-center align-md-items-between justify-content-center"
              >
                <ColTitle className="text-center">
                  Automation Software as a Service
                </ColTitle>
                <p>
                  Get registered and access your dashboard fast within 60
                  seconds of filling out the contact form.
                </p>
              </Col>
              <Col
                xs={9}
                sm={10}
                md={4}
                className="d-flex flex-column align-items-between justify-content-center"
              >
                <ColTitle className="text-center">
                  Business Intelligence Tool
                </ColTitle>
                <p>
                  Get registered and access your dashboard fast within 60
                  seconds of filling out the contact form.
                </p>
              </Col>
            </Row>
          </ProviderContainer>
        </DashboardCol>
      </DashboardRow>
    </>
  );
}

const BannerGlobalStyle = createGlobalStyle`
  #sign-up-for-free-btn:hover {
    background-color: #;
    border-color: #4b0069;

  }
  #sign-up-for-free-btn * {
    z-index: 10000;
  }
`;

const DashboardRow = styled(Row)`
  background: linear-gradient(
    359.68deg,
    rgb(112, 0, 156, 0.1),
    rgb(255, 140, 46, 0.1)
  );
  width: 100vw;
  padding: 0;
  margin: 0;
`;

const BannerTitle = styled.h1`
  font-size: 68px;
  letter-spacing: -0.03em;
  @media only screen and (max-width: 681px) {
    font-size: 36px;
  }
`;

const DashboardCol = styled(Col)`
  margin: -7.5rem 0 3rem 0;
  width: 100vw;
  @media only screen and (max-width: 681px) {
    margin: -6rem 0 2rem 0;
  }
`;

const DashboardImage = styled(Image)`
  @media only screen and (max-width: 681px) {
    width: 75vw;
    margin-bottom: 2rem;
  }
`;

const ProviderContainer = styled(Row)`
  margin: '150px 0 120px 0';
  @media only screen and (max-width: 681px) {
    margin: '4rem 0 1rem 0';
    width: 100vw;
    padding: 0 !important;
  }
`;

const ContainerTitle = styled.h2`
  font-size: 36px;
  margin: 100px 0 0 0;
  @media only screen and (max-width: 681px) {
    font-size: 32px;
    margin: 0;
  }
`;

const ColTitle = styled.h3`
  font-size: 24px;
  @media only screen and (max-width: 681px) {
    font-size: 18px;
  }
`;

const ProviderImage = styled(Image)`
  @media only screen and (max-width: 681px) {
    display: none;
  }
`;

export default Banner;
