/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import React, { useState } from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import {
  Form,
  Input,
  Typography,
  Row,
  Col,
  Modal,
  Button,
  message,
} from 'antd';
import { UserOutlined, MailOutlined } from '@ant-design/icons';
import axios from 'axios';
import qs from 'qs';
import dotEnv from '../dotEnv';
import SignUpModalGlobalStyle from './SignUpModalGlobalStyle';

import { ReactComponent as RundisIcon } from '../images/Rundis.svg';

const { Item, useForm } = Form;
const { Paragraph, Title } = Typography;
const { CLIENT_ID, CLIENT_SECRET, TOKEN_URL } = dotEnv;

const parseName = fullname => {
  return {
    first_name: fullname.split(' ')[0],
    last_name: fullname.split(' ')[1],
  };
};

const getToken = async () => {
  try {
    return axios({
      url: TOKEN_URL,
      method: 'post',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      data: qs.stringify({
        grant_type: 'client_credentials',
        client_id: CLIENT_ID,
        client_secret: CLIENT_SECRET,
      }),
      timeout: 3000,
    });
  } catch (error) {
    console.log('auth error: ', error);
  }
};

const ModalTitle = () => (
  // eslint-disable-next-line react/jsx-wrap-multilines
  <Navbar className="d-flex justify-content-between">
    <Navbar.Brand href="#home" className="ml-md-5">
      <Title className="logo bg-light d-flex align-items-baseline">
        <RundisIcon />
      </Title>
    </Navbar.Brand>
    <Nav className="d-flex ">
      {/* <Nav.Item as="p" className="have-an-account ml-4">
        <Nav.Link disabled>Have an Account?</Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link
          href="https://crm.rundis.io/login"
          id="login-link-sign-up-modal"
        >
          Login
        </Nav.Link>
      </Nav.Item> */}
    </Nav>
  </Navbar>
);

// eslint-disable-next-line react/prop-types
const SignUpForm = ({ modalVisible, setModalVisible }) => {
  const [form] = useForm();
  const formName = 'signUpForm';
  const [tenantId, setTenantId] = useState('');
  const apiUrl = 'https://crm.rundis.io/Api/V8/module';

  const login = async () => {
    const response = await getToken();
    if (response) {
      if (response.data && response.data.access_token) {
        console.log('auth data ', response.data);
        return response.data;
      }
    }
  };

  const createFormPost = (data, url, token) => {
    const formPostRequest = {
      method: 'post',
      url: url,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      data: JSON.stringify(data),
    };

    return axios(formPostRequest)
      .then(response => {
        console.log(JSON.stringify(response.data));
        // eslint-disable-next-line no-console
        setTenantId(response.data.data.attributes.rundis_tenant_url_c);
        console.log(tenantId);
      })
      .catch(err => {
        const errObj = err.response.data.errors;
        for (const property in errObj) {
          // render ant d toasts
          message.error(errObj[property]);
        }
      });
  };

  const handleSubmit = inputs => {
    const name = parseName(inputs.name);
    // eslint-disable-next-line
    const data = {
      data: {
        type: 'Contact',
        attributes: {
          ...name,
          sign_up_c: true,
          lead_source: 'Web Site',
          email1: inputs.email,
          email_addresses_primary: inputs.email,
        },
      },
    };

    console.log('form data: ', data);

    (async () => {
      const auth = await login();
      createFormPost(data, apiUrl, auth.access_token);
      setModalVisible(false);
    })();
  };

  return (
    <>
      <SignUpModalGlobalStyle />
      <Modal
        id="sign-up-modal"
        title={<ModalTitle />}
        centered
        visible={modalVisible}
        onCancel={() => setModalVisible(false)}
        className="sign-up-modal"
        footer=""
      >
        <Row justify="space-around">
          <Col xs={24} sm={22} md={20}>
            <Title id="orange-title-sign-up-modal" className="col-md-10">
              Sign Up for Free
            </Title>
            <Paragraph className="signup-modal-paragraph col-md-10">
              After creating your account, we’ll work you through how to create
              your first workplace with Rundis
            </Paragraph>
          </Col>
        </Row>
        <Row justify="space-around">
          <Col xs={24} sm={22} md={20}>
            <Form
              name={formName}
              method="post"
              onFinish={handleSubmit}
              layout="vertical"
              form={form}
              // onValuesChange={changedValues => {
              //   setForm(current => ({ ...current, ...changedValues }));
              // }}
            >
              {/* This is the hidden field that the netlify-honeypot uses. */}
              <Item
                label="Don't fill this out"
                name="sign_up_c"
                className="hidden"
                style={{ display: 'none' }}
              >
                <Input type="hidden" value />
              </Item>

              <Item
                label="Name"
                name="name"
                rules={[{ required: true, message: 'Please enter your name.' }]}
              >
                <Input
                  placeholder="Enter your name"
                  prefix={<UserOutlined className="site-form-item-icon" />}
                  size="large"
                />
              </Item>

              <Item
                label="Email"
                name="email"
                rules={[
                  {
                    required: true,
                    type: 'email',
                    message: 'Please enter your email.',
                  },
                ]}
              >
                <Input
                  placeholder="Enter you email"
                  prefix={<MailOutlined className="site-form-item-icon" />}
                  size="large"
                />
              </Item>
              <Item className="button-wrapper">
                <Button
                  className="sign-up-submit"
                  type="primary"
                  htmlType="submit"
                  disabled={false}
                >
                  Send
                </Button>
              </Item>
              <Item className="have-an-account">
                {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
                Have an Account?{' '}
                <a
                  href="https://crm.rundis.io/login"
                  id="login-link-sign-up-modal"
                >
                  Login
                </a>
              </Item>
            </Form>
          </Col>
        </Row>
      </Modal>
    </>
  );
};

export default SignUpForm;
