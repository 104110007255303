/* eslint-disable quotes */
import React from 'react';
// eslint-disable-next-line object-curly-newline
import { Image, Row, Col } from 'react-bootstrap';
import { Button } from 'antd';
import styled from 'styled-components';
import arlington from '../images/arlington.jpg';
import firos from '../images/firos.jpg';
import riccardo from '../images/riccardo.jpg';

// eslint-disable-next-line react/prop-types
function GridContent({ setModalVisible }) {
  return (
    <>
      <TopRow className="justify-content-around align-items-center top-row">
        <Col
          xs={{ span: 10, order: 2 }}
          md={{ span: 5, order: 1 }}
          style={{ margin: '0', width: '100vw' }}
        >
          <ContentHeader className="mb-4">
            Setup is quick & easy. No Code required
          </ContentHeader>
          <ContentBox>
            Get registered and access your dashboard fast within 60 seconds of
            filling out the contact form.
          </ContentBox>
          <ContentBox>
            Once in the app, adding a new data provider is simple.
          </ContentBox>
          <ContentBox>
            Add a data provider within minutes of working with the application,
            even with no coding experience.
          </ContentBox>
          <SignUpButton
            className="bg-white text-primary"
            onClick={() => setModalVisible(true)}
          >
            Sign Up for Free
          </SignUpButton>
        </Col>
        <Col xs={{ span: 10, order: 1 }} md={{ span: 5, order: 2 }}>
          <Image
            src={arlington}
            className="border-0 mb-5"
            alt="two women look at a computer screen"
            fluid
          />
        </Col>
      </TopRow>
      <Row
        className="d-flex flex-row justify-content-around align-items-center"
        style={{ marginBottom: '150px' }}
      >
        <Col xs={10} md={5}>
          <Image
            src={firos}
            className="border-0 mb-5"
            alt="one man staring into a computer screen"
            fluid
          />
        </Col>
        <Col xs={10} md={5}>
          <ContentHeader className="mb-4">
            Integrate Tools & People, All in One-place
          </ContentHeader>
          <ContentBox>
            Connect your app and get insights & actions based on the data of
            your equipment or employees.
          </ContentBox>
          <ContentBox>
            Easy to connect all your event-driven processes to get up and
            running sooner without having to wait months for a custom solution.
          </ContentBox>
          <ContentBox>
            Track all sorts of data such as Compliance, inventory, remote
            working, locales, versioning of devices
          </ContentBox>
          <SignUpButton
            className="bg-white text-primary"
            onClick={() => setModalVisible(true)}
          >
            Sign Up for Free
          </SignUpButton>
        </Col>
      </Row>
      <Row
        className="justify-content-around align-items-center"
        style={{ marginBottom: '150px' }}
      >
        <Col xs={{ span: 10, order: 4 }} md={{ span: 5, order: 3 }}>
          <ContentHeader className="mb-4">
            Compelling Artificial Intelligence tool
          </ContentHeader>
          <ContentBox>
            Rundis will disrupt your day-to-day monotony and uncover the useful
            information that you need to know about your business & help your
            organization understand its information systems.
          </ContentBox>
          <ContentBox>
            None of the automation competitors has the experience and machine
            learning tools to suggest actionable steps like Rundis.
          </ContentBox>
          <ContentBox>
            The Rundis revolution is disruptive to the status quo.
          </ContentBox>
          <SignUpButton
            className="bg-white text-primary"
            onClick={() => setModalVisible(true)}
          >
            Sign Up for Free
          </SignUpButton>
        </Col>
        <Col xs={{ span: 10, order: 3 }} md={{ span: 5, order: 4 }}>
          <Image
            src={riccardo}
            className="border-0 mb-5"
            alt="man in a suit holding a lit light bulb"
            fluid
          />
        </Col>
      </Row>
    </>
  );
}

const TopRow = styled(Row)`
  margin: 150px 0;
  width: 100vw;

  @media only screen and (max-width: 681px) {
    margin: 3rem 0 !important;
    width: 100vw;
    margin: 0;
  }
`;
const ContentHeader = styled.h2`
  max-width: 482.59px;
  font-family: 'Arimo', sans-serif;
  font-size: 48px;
  line-height: 110%;
  /* or 53px */
  letter-spacing: -0.05em;
  /* Black */
  color: #3f3f3f;
  @media only screen and (max-width: 681px) {
    max-width: 300px;
    font-size: 32px;
    text-align: center;
    margin: 0;
  }
`;

const ContentBox = styled.p`
  max-width: 482.59px;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 26px;
  /* or 144% */
  letter-spacing: -0.02em;
  /* Black */
  color: #3f3f3f;
  @media only screen and (max-width: 681px) {
    max-width: 300px;
    margin: 0;
  }
`;
const SignUpButton = styled(Button)`
  border: 0px;
  height: 25px;
  width: 200px;
  font-family: 'Inter';
  font-size: 16px;
  line-height: 124.5%;
  /* identical to box height, or 20px */

  text-align: center;
  letter-spacing: -0.02em;
  text-decoration-line: underline;

  color: #8f1e85;
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export default GridContent;
