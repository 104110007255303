/* eslint-disable quotes */
import React from 'react';
// eslint-disable-next-line object-curly-newline
import { Container, Row, Col, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

// eslint-disable-next-line react/prop-types
function Pricing({ setModalVisible }) {
  return (
    <Container>
      <Row>
        <Col>
          <PricingTitle>Plans & Pricing</PricingTitle>
        </Col>
      </Row>
      <Row>
        <Col
          className="card-deck mb-3 text-center  d-sm-flex flex-sm-column d-md-flex flex-md-column flex-md-wrap d-lg-flex flex-lg-row align-items-sm-center justify-content-md-center"
          // style={{ minWidth: "40%", maxWidth: "60%" }}
        >
          <Card className="card mb-5 box-shadow w-sm-50 w-md-60 w-lg-30 mx-md-3 mx-lg-5">
            <CardHeader className="card-header">
              <CardTitle className="my-0 font-weight-normal">
                Individual
              </CardTitle>
              <CardSubTitle>1 User</CardSubTitle>
            </CardHeader>
            <CardBody className="card-body">
              <Price className="card-title pricing-card-title">$0</Price>
              <Button
                type="button"
                variant="outline-primary"
                className="btn btn-lg btn-block"
                onClick={() => setModalVisible(true)}
              >
                Signup Free
              </Button>
            </CardBody>
          </Card>
          <Card className="card mb-5 box-shadow w-sm-50 w-md-70 w-lg-30 mx-md-3  mx-lg-5">
            <CardHeader className="card-header">
              <CardTitle className="my-0 font-weight-normal">Team</CardTitle>
              <CardSubTitle>2 Users to 1,000 users</CardSubTitle>
            </CardHeader>
            <CardBody className="card-body">
              <Price className="card-title pricing-card-title">TBD</Price>
              <Button
                type="button"
                className="btn btn-lg btn-block btn-primary"
              >
                Get started
              </Button>
            </CardBody>
          </Card>
          <Card className="card mb-5 box-shadow w-sm-50 w-md-80 w-lg-30 mx-md-3 mx-lg-5">
            <CardHeader className="card-header">
              <CardTitle className="my-0 font-weight-normal">
                Enterprise
              </CardTitle>
              <CardSubTitle>Above 1,000 Users </CardSubTitle>
            </CardHeader>
            <CardBody className="card-body">
              <Price className="card-title pricing-card-title">TBD</Price>
              <Link to="#contact-form">
                <Button
                  type="button"
                  className="btn btn-lg btn-block btn-primary"
                >
                  Contact us
                </Button>
              </Link>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

const PricingTitle = styled.h2`
  font-size: 48px;
  text-align: center;
  margin-top: 124px;
  margin-bottom: 50px;
  @media only screen and (max-width: 681px) {
    margin-top: 100px;
    margin-bottom: 24px;
    font-size: 32px;
  }
`;

const Card = styled.div`
  background: #fff3e9;
  border-radius: 0px 4px 0px 0px;
  width: 300px;
  height: 350px;
  /* display: flex;
  justify-content: center;
  align-items: center; */
  margin: 0 auto;
    @media only screen and (max-width: 450px) {
    width: 200px;
  }
`;

const CardHeader = styled.div`
  background-color: inherit;
  margin-top: 45px;
`;

const CardTitle = styled.h4`
  font-family: Inter;
  font-weight: 600;
  font-size: 24px;
`;

const CardSubTitle = styled.p`
  font-family: Inter;
  font-size: 12px;
  line-height: 142%;
  /* identical to box height, or 17px */
  letter-spacing: -0.02em;
  text-align: center;
  /* Neutrals/500 */
  color: #495057;
  margin-top: 0.5rem;
`;

const Price = styled.h4`
  font-family: Inter;
  font-weight: 600;
  font-size: 24px;
  line-height: 120%;
  /* identical to box height, or 29px */
  text-align: center;
  /* Neutrals/600 */
  color: #212429;
  margin: 1.5rem auto 2rem auto;
`;

const CardBody = styled.div`
  margin-bottom: 25px;
`;

export default Pricing;
