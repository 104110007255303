/* eslint-disable object-curly-newline */
/* eslint-disable quotes */
/* eslint-disable react/react-in-jsx-scope */
import React, { useState } from 'react';
import { Layout } from 'antd';
import { BrowserRouter as Router } from 'react-router-dom';
import Main from './layout/Main';
import Banner from './components/Banner';
import GridContent from './components/GridContent';
import Features from './components/Features';
import CliAndPricing from './components/CliAndPricing';
import ContactForm from './components/ContactForm';
import RundisHeader from './components/RundisHeader';

const { Content, Footer } = Layout;

function App() {
  const [modalVisible, setModalVisible] = useState(false);

  return (
    <>
      <Router>
        <Main>
          <RundisHeader
            modalVisible={modalVisible}
            setModalVisible={setModalVisible}
          />
          <Content className="site-layout" style={{}}>
            <Banner className="mb-5" setModalVisible={setModalVisible} />
            <GridContent
              className="mb-5"
              style={{ marginTop: 64, marginRight: 0, marginLeft: 0 }}
              setModalVisible={setModalVisible}
            />
            <Features className="mb-5" />
            <CliAndPricing className="mb-5" setModalVisible={setModalVisible} />
            <ContactForm />
          </Content>
          <Footer className="d-md-flex justify-content-around d-sm-block">
            <p className="text-center">
              {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
              Rundis, a MJ Group company 	&copy;{new Date().getFullYear()}
            </p>
            <p className="text-center">Created by devSouth Consulting, LLC</p>
          </Footer>
        </Main>
      </Router>
    </>
  );
}

export default App;
