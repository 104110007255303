/* eslint-disable quotes */
import React from 'react';
// eslint-disable-next-line object-curly-newline
import { Container, Image, Row, Col } from 'react-bootstrap';
import { Typography } from 'antd';
import styled from 'styled-components';
import AwesomeSlider from 'react-awesome-slider';
import withAutoplay from 'react-awesome-slider/dist/autoplay';
import 'react-awesome-slider/dist/styles.css';
import RundisCli from '../images/rundis-cli.png';

const { Title, Text } = Typography;
const AutoplaySlider = withAutoplay(AwesomeSlider);

const CliCarousel = () => (
  <Container>
    <Row className="d-flex justify-content-center">
      <Col sm={11}>
        <AutoplaySlider
          // eslint-disable-next-line react/jsx-boolean-value
          play={true}
          cancelOnInteraction={false} // should stop playing on user interaction
          interval={5000}
        >
          <div>
            <CarouselImage src={RundisCli} alt="First slide" fluid />
          </div>
          <div>
            <CarouselImage src={RundisCli} alt="Second slide" fluid />
          </div>
          <div>
            <CarouselImage src={RundisCli} alt="Third slide" fluid />
          </div>
        </AutoplaySlider>
      </Col>
    </Row>
    <TitleStyled level={3} className="text-center" strong>
      Simple & Efficient CLI
    </TitleStyled>
    <div className="d-flex align-items-center justify-content-center mx-auto">
      <TextStyled className="text-center">
        The product is language agnostic allowing for custom actions. Tool
        integrates the change does that for you. It works similar to what AWS
        lambda does that for you.
      </TextStyled>
    </div>
  </Container>
);

const CarouselImage = styled(Image)`
  width: 100%;
`;

const TitleStyled = styled(Title)`
  font-family: Inter;
  font-size: 30px;
  line-height: 36px;
  margin-top: 5rem;
  @media only screen and (max-width: 681px) {
    font-size: 20px;
  }
`;

const TextStyled = styled(Text)`
  font-family: Inter;
  font-size: 18px;
  line-height: 22px;
  /* Black */
  color: #3f3f3f;
  margin-top: 50px;
  @media only screen and (max-width: 681px) {
    font-size: 1rem;
  }
`;
export default CliCarousel;
