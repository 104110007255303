/* eslint-disable quotes */
import React from 'react';
// eslint-disable-next-line object-curly-newline
import { Container, Row, Col } from 'react-bootstrap';
import { Typography } from 'antd';
import styled from 'styled-components';
import CliCarousel from './CliCarousel';
import Pricing from './Pricing';

const { Title } = Typography;

// eslint-disable-next-line react/prop-types
function CliAndPricing({ setModalVisible }) {
  return (
    <>
      <Container
        id="cli"
        style={{ marginTop: '75px', marginBottom: '75px' }}
        fluid
      >
        <Row>
          <Col md>
            <CliTitle
              level={4}
              className="text-center"
              style={{ marginBottom: '50px' }}
              strong
            >
              Rundis CLI in Action
            </CliTitle>
          </Col>
        </Row>
        <Row className="d-flex justify-content-center">
          <Col>
            <CliCarousel />
          </Col>
        </Row>
      </Container>
      <Container id="pricing" style={{ margin: '75px auto' }}>
        <Row>
          <Col md className="mx-auto">
            <Pricing setModalVisible={setModalVisible} />
          </Col>
        </Row>
      </Container>
    </>
  );
}

const CliTitle = styled(Title)`
  /* H4-32px */
  font-family: Inter;
  line-height: 110%;
  font-size: 48px;
  /* or 35px */
  letter-spacing: -0.05em;
  /* Black */
  color: #3f3f3f;
  @media only screen and (max-width: 681px) {
    font-size: 32px;
  }
`;

export default CliAndPricing;
