import { createGlobalStyle } from 'styled-components';

const SignUpModalGlobalStyle = createGlobalStyle`

.login-btn {
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  /* identical to box height, or 24px */
  text-align: center;
  letter-spacing: -0.045em;
  /* Black */
  color: #3F3F3F;
}

#sign-up-modal {
  background: linear-gradient(359.68deg, rgba(255, 140, 46, 0.05) 18.98%, rgba(112, 0, 156, 0.05) 100.01%);
  /* opacity: 0.1; */
  border-radius: 8px;
  z-index: 1002;
}

.ant-modal-header {
  border: none;
}

.have-an-account{
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  /* identical to box height, or 24px */
  display: flex;
  align-items: center;
  text-align: left;
  letter-spacing: -0.045em;
  /* Gray 3 */
  color: #828282;
}

#login-link-sign-up-modal {
  color: #550076;
}

#orange-title-sign-up-modal {
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 24px;
  /* identical to box height, or 75% */
  letter-spacing: -0.02em;
   color: #F27243;
 }

.sign-up-modal-paragraph{
  /* Paragraph */
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  /* or 150% */
  display: flex;
  align-items: center;
  letter-spacing: -0.02em;
  /* Gray 1 */
  color: #333333;
}

.sign-up-modal .ant-form-item-label label {
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  /* identical to box height */
  /* Gray 2 */
  color: #4F4F4F;
}

.sign-up-modal .ant-input-affix-wrapper .ant-input-prefix {
  margin-left: 20px;
  margin-right: 10px;
}

.sign-up-modal .ant-input-affix-wrapper .ant-input {
  height: 40px;
  min-width: 80%;
  max-width: 100%;
  border-radius: 8px;
}
.sign-up-modal .ant-input {

  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height, or 125% */
  display: flex;
  align-items: center;
  /* Gray 3 */
  color: #828282;
}

.sign-up-modal .ant-modal-footer{
  border: none;
}

.sign-up-modal .button-wrapper {


}

.sign-up-modal .ant-btn-primary, .sign-up-modal .ant-btn {
  height: 54px;
  width: 211px;
  border-radius: 8px;
  padding: 15px, 60px, 15px, 60px;
  color: #fff;
  border-color: #4b0069;
  /* Paragraph */
  font-family: Inter;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  text-align: center;
  letter-spacing: -0.02em;
}

.sign-up-modal .ant-btn-primary:hover {
  background-color: #550076;
  border-color: #4b0069;
  color: #fff;
}

.sign-up-modal .ant-btn-primary:active {
  background-color: #fff;
  border-color: #4b0069;
  color: #550076;
}

.sign-up-modal .ant-btn-primary:focus {
  background-color: #fff;
  border-color: #4b0069;
  color: #550076;
}
`;

export default SignUpModalGlobalStyle;
