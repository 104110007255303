/* eslint-disable react/prop-types */
import React from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import { Button } from 'antd';
import { createGlobalStyle } from 'styled-components';
import SignUpForm from './SignUpForm';

import SvgIcon from './SvgIcon';

const RundisHeader = ({ modalVisible, setModalVisible }) => (
  <>
    <HeaderGlobalStyle />
    <Navbar
      style={{ width: '100vw' }}
      bg="light"
      expand="md"
      fixed="top"
      className="shadow-sm p-xs-0"
    >
      <Navbar.Brand href="#home" className="ml-md-5">
        <h1 className="logo bg-light d-flex align-items-baseline">
          <SvgIcon />
        </h1>
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse
        id="responsive-navbar-nav"
        className="bg-light mh-lg-100"
      >
        <Nav className="mr-auto">
          <Nav.Item className="ml-4">
            <Nav.Link href="#features" className="pl-sm-3 ml-4  my-lg-0">
              Features
            </Nav.Link>
          </Nav.Item>
          <Nav.Item className="ml-4">
            <Nav.Link href="#cli" className="pl-sm-3 ml-4  my-lg-0">
              CLI
            </Nav.Link>
          </Nav.Item>
          <Nav.Item className="ml-4">
            <Nav.Link href="#pricing" className="pl-sm-3 ml-4  my-lg-0">
              Pricing
            </Nav.Link>
          </Nav.Item>
        </Nav>
        <Nav.Item className="my-lg-0 mr-3">
          <Nav.Link href="https://crm.rundis.io/login">
            <Button
              // eslint-disable-next-line
              // onClick={() => console.log('Login Screen Pops Up!')}
              type="text"
              className="border-0 mx-xl-3 ml-xs-5 w-100 my-lg-0 login-btn"
              style={{ background: '#F8F9FA' }}
            >
              Login
            </Button>
          </Nav.Link>
        </Nav.Item>
        <Nav.Item className="mr-xl-5">
          <div className="w-100 d-flex justify-content-center mr-xl-5">
            <Button
              id="sign-up-btn-nav-link"
              className="btn-primary mx-sm-auto mr-lg-5 mr-xl-5 my-sm-3"
              onClick={() => setModalVisible(true)}
              style={{
                width: '140px',
                height: '48px',
                borderRadius: '8px',
                // eslint-disable-next-line quotes
                fontFamily: "'Roboto', sans-serif",
                fontStyle: 'normal',
                fontWeight: '500',
                fontSize: '16px',
                lineHeight: '18px',
              }}
            >
              Sign Up
            </Button>
          </div>
        </Nav.Item>
      </Navbar.Collapse>
    </Navbar>
    <SignUpForm modalVisible={modalVisible} setModalVisible={setModalVisible} />
  </>
);

const HeaderGlobalStyle = createGlobalStyle`
 #sign-up-btn-nav-link:hover {
   color: #fff;
 }
`;

export default RundisHeader;
